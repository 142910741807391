import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import tree, { treeInverse } from '../../../components/order/tree';
import { pricingInverse, metadata } from '../../../services/pricing';
import { formatCloth, formatSlots } from '../../../services/orderFormatting';
import featureSwitch from '../../../services/featureSwitch';
import routesMap from '../../../Routes';
import withAppContext from '../../../withAppContext';
import withOrderContext from '../../../withOrderContext';
import {
  StyledTitle2 as Title2V3, FormInput as FormInputV2,
  mobileThresholdPixels, colors,
} from '../../../components/home/v3/styledComponents';
import OrderSummaryContainer from '../../../components/order/OrderSummaryContainer';
import { Wrapper, Block } from '../../../components/order/styledComponents';
import OrderContainer from '../../common/OrderContainer';
import callApi from '../../../services/api';
import checkZipcodeError, { getLocality, getDeliveryFees } from '../../../services/zipcode';
import { isEmailValid } from '../../../services/checkingFormat';
import LogoButton from '../../../components/order/LogoButton';
import tick from '../../../assets/platform/tick.png';
import errorLogo from '../../../assets/platform/error.png';
import verifiedLogo from '../../../assets/platform/verified.png';
import OutInSideClick from '../../../components/home/v2/OutInSideClick';
import Dots from '../../../components/home/v3/Dots';
import ProgressBar from '../../../components/order/ProgressBar';

const inputs = {
  seller: { value: '', name: 'seller', label: featureSwitch('infos_noShopLabel') ? 'Nom du vendeur' : 'Nom du vendeur - boutique', size: 'large', isMobileLarge: true },
  firstname: { value: '', name: 'firstname', label: 'Prénom', isRequired: true, size: 'small', isMobileLarge: true },
  lastname: { value: '', name: 'lastname', label: 'Nom', isRequired: true, size: 'medium', isMobileLarge: true },
  street: {
    value: '',
    name: 'street',
    label: 'Adresse (voie)',
    isRequired: true,
    size: 'large',
    isMobileLarge: true,
  },
  comment: {
    value: '',
    name: 'comment',
    label: "Complément d'adresse",
    isRequired: false,
    size: 'large',
    isMobileLarge: true,
    // eslint-disable-next-line max-len
    more: 'Indiquez le bâtiment, l’étage, le code et toutes les indications qui pourront faciliter l’accès à notre couturier.' },
  zipcode: {
    value: '',
    name: 'zipcode',
    label: 'Code postal',
    isRequired: true,
    size: 'small',
    isMobileLarge: false,
  },
  locality: { value: '', name: 'locality', label: 'Ville', isRequired: true, size: 'medium', isMobileLarge: false },
  errorZipcode: { name: 'errorZipcode', isActive: false, label: 'Ville pas encore desservie :(' },
  email: { value: '', name: 'email', label: 'Adresse mail', isRequired: true, size: 'large', isMobileLarge: true },
  errorEmail: { name: 'errorEmail', isActive: false, label: 'Adresse non valide' },
  phone: {
    value: '',
    name: 'phone',
    label: 'Numéro de téléphone',
    isRequired: true,
    size: 'small',
    isMobileLarge: true,
    more: 'Indiquez votre numéro de téléphone, notre couturier en aura besoin pour vous contacter. ',
  },
};

const formInputWidths = {
  small: { desktop: 143, mobile: 87 },
  medium: { desktop: 193, mobile: 124 },
  large: { desktop: 373, mobile: 258 },
};

const Title2 = styled(Title2V3)`
  margin-bottom: 81px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 30px;
    margin-bottom: 6px;
  }
`;

const ComponentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
    ${props => props.marginBottom && 'margin-bottom: 60px;'}
    margin-top: 0px;
  }
`;

const ButtonContainer = styled(ComponentsContainer)`
  margin-bottom: 100px;
  margin-top: 60px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 60px;
  }
`;

const ContactInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 552px;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    width: 275px;
  }
`;

const TitleContactInfosContainer = styled.div`
  font-family: Gotham;
  font-weight: 500;
  line-height: 30px;
  font-size: 24px;
  display:flex;
  margin-bottom: 55px;
  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 20px;
    font-size: 18px;
    margin-bottom: ${props => props.mobileMarginBottom ? '60' : '30'}px;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const TitleContactInfos = styled.p`
  color: ${colors.navy};
  margin: 0px 9px 0px 0px;
  cursor: pointer;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 30px;
    margin-right: 0px;
  }
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 18px;
  margin-bottom: ${props => !props.notLabelForm ? '5' : '40'}px;
  margin-top: ${props => !props.notLabelForm ? '0' : '-35'}px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    ${props => props.notLabelForm && 'margin-top: 0px; margin-bottom: 20px;'};
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  color: ${colors.navy};
`;

const FormInput = styled(FormInputV2)`
  margin: 0px ${({ error }) => error !== undefined ? '10' : '20'}px 20px 0px;
  border-radius: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 38px;
  font-family: Roboto;
  font-size: 15px;
  outline: 0;
  border: solid 0.5px ${colors.navy};
  width: ${props => `${formInputWidths[props.size].desktop}`}px;
  color: ${colors.navy};
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px hsl(1, 100%, 100%) inset;
    -webkit-text-fill-color: ${colors.navy};
  };
  @media (max-width: ${mobileThresholdPixels}) {
    ${props => (props.isMobileLarge || props.size !== 'small') && 'margin-right: 0px;'}
    width: ${props => props.isMobileLarge
    ? formInputWidths.large.mobile
    : formInputWidths[props.size].mobile}px;
    background-position-x: ${props => props.isMobileLarge
    ? formInputWidths.large.mobile - 8
    : formInputWidths[props.size].mobile - 8}px;
  }
`;

const MoreInfos = styled.p`
  ${props => props.noDisplay && 'display: none;'}
  font-family: Roboto;
  line-height: 20px;
  font-size: 14px;
  width: 373px;
  margin-top: -10px;
  margin-bottom: 20px;
  ${props => props.error && `
    color: ${colors.red};
    width: 145px;
    margin-top: ${props.errorOn2Lines ? '20%' : '28%'};
    margin-left: -6px;
  `}
  ${props => props.topInfo && `
    width: auto;
    margin: -21px 0px 14px;
  `}
  ${({ small }) => small && 'width: 100px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 258px;
    line-height: 17px;
    font-size: 12px;
    ${props => props.error && `
      width: ${props.errorOn2Lines ? '200px' : '135px'};
      margin-top: -15px;
      margin-left: 0px;
    `}
    ${props => props.topInfo && `
      width: auto;
      margin-top: -5px;
    `}
  }
`;

const PopUpContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  height: auto;
  margin: 0 auto;
  display: ${props => props.isDisplayed ? 'flex' : 'none'};
  align-items: center;
  z-index: 2;
  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    padding: 18px 22px;
  }
`;

const PopUpLocality = styled(PopUpContainer)`
  background-color: ${colors.popup};
  color: ${colors.white};
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  width: 373px;
  padding: 34px 67px 34px 40px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 107px);
  }
`;

const PopUpWaitTime = styled(PopUpContainer)`
  background-color: ${colors.white};
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;
  width: 307px;
  padding: 54px 77px;
  flex-direction: column;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 50px);
    padding: 54px 25px;
  }
`;

const PopUpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 49px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: 17px;
  }
`;

const PopUpText = styled.p`
  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: 5px;
  ${props => props.pointer && 'cursor: pointer;'}
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

const InputAndImgContainer = styled.div`
  display: flex;
`;

const I = styled.i`
  background-image: ${({ error }) => error ? `url(${errorLogo})` : `url(${verifiedLogo})`};
  background-size: contain;
  width: 10px;
  height: 10px;
  position: relative;
  left: -30px;
  top: 15px;
`;

class MeetingInfos extends Component {
  constructor() {
    super();
    this.state = {
      formInputs: { ...inputs },
      popUpLocalityIsOpen: false,
      popUpWaitTimeIsOpen: false,
      deliveryFee: 5,
    };
    this.setFormInputValue = this.setFormInputValue.bind(this);
    this.checkFormInputValue = this.checkFormInputValue.bind(this);
    this.displayDeliveryFees = this.displayDeliveryFees.bind(this);
    this.updateDeliveryFees = this.updateDeliveryFees.bind(this);
    this.setFormInputs = this.setFormInputs.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.removePromoCode = this.removePromoCode.bind(this);
    this.checkPromoCode = this.checkPromoCode.bind(this);
  }

  componentDidMount() {
    if (featureSwitch('infos_codePromoButton') && featureSwitch('infos_codePromoAlways')) {
      this.checkPromoCode(featureSwitch('infos_codePromoButton'));
    }
  }

  setFormInputs(formInputs) {
    this.setState({ formInputs });
  }

  setFormInputValue(event, listName) {
    const { target: { name, value } } = event;
    const newFormInputs = { ...this.state[listName] };
    if (name === 'zipcode') {
      newFormInputs.locality.value = getLocality(value) || '';
    }
    newFormInputs[name].value = value;
    this.setState({ [listName]: { ...newFormInputs } });
  }

  setPopUpIsOpen(popUpLocalityIsOpen) {
    this.setState({ popUpLocalityIsOpen });
  }

  displayDeliveryFees(zipcode) {
    const deliveryFees = getDeliveryFees(zipcode) || 0;
    this.setState({
      deliveryFees,
    });
    this.updateDeliveryFees(deliveryFees);
    if (deliveryFees === 15) {
      this.setPopUpIsOpen(true);
    }
  }

  addAddress() {
    this.setIsAlreadyCustomer(true);
    const { formInputs } = this.state;
    const newFormInputs = { ...formInputs };
    newFormInputs.street.value = '';
    newFormInputs.comment.value = '';
    newFormInputs.zipcode.value = '';
    newFormInputs.locality.value = '';
    newFormInputs.street.error = undefined;
    newFormInputs.zipcode.error = undefined;
    newFormInputs.locality.error = undefined;
    this.setState({
      addingNewAddress: true,
      formInputs: { ...newFormInputs },
    });
  }

  createCustomerRequest() {
    const { formInputs } = this.state;
    const { firstname, lastname, street, comment, zipcode, locality, phone, email } = formInputs;
    const addressFromForm = {
      street: street.value,
      comment: comment.value,
      zipcode: zipcode.value,
      locality: locality.value,
    };
    return {
      firstname: firstname.value,
      lastname: lastname.value,
      phone: phone.value,
      email: email.value,
      address: addressFromForm,
    };
  }

  createOrderRequest(customer) {
    const { clothes, slots, rdv3ClicksOrder, promoCode } = this.props.orderContext;
    let fabrics = [];
    if (clothes.length !== 0) {
      fabrics = clothes.reduce(
        (fabricsAcc, { selectedFabric }) => selectedFabric !== ''
          ? [...fabricsAcc, selectedFabric]
          : [...fabricsAcc]
        , []);

      if (clothes.find(cloth => cloth.selectedCloth === 'mari')) {
        fabrics = [...fabrics, 'silk', 'lace'];
      }

      if (clothes.find(cloth => cloth.selectedPiece === 'ride')) {
        fabrics.push('curtain');
      }

      if (clothes.find(cloth => cloth.selectedTypeOfWork === 'creation')) {
        fabrics.push('creation');
      }
    } else {
      const knowHow = rdv3ClicksOrder.knowHow.map(({ slug }) => slug);
      fabrics = rdv3ClicksOrder.fabrics;
      if (knowHow.includes('embroidery')) fabrics.push('embroidery');
      if (knowHow.includes('curtain')) fabrics.push('curtain');
      if (knowHow.includes('mari')) fabrics.push('silk', 'lace');
      if (knowHow.includes('creation')) fabrics.push('creation');
    }

    fabrics = fabrics.filter(fabric => fabric !== 'other');

    const rdv1Slots = formatSlots(slots);

    const brand = promoCode && promoCode.brand;

    return {
      rdv1: rdv1Slots[0],
      rdv1Slots,
      fabrics,
      promoCode: promoCode && promoCode.code,
      minOrderAmount: metadata.minOrderAmount,
      deliveryFee: getDeliveryFees(customer && customer.address && customer.address.zipcode),
      pricingVersion: metadata.version,
      clothes: clothes.map(cloth => formatCloth(tree, treeInverse, pricingInverse, cloth, brand)),
      seller: this.state.formInputs.seller && this.state.formInputs.seller.value,
      brand: process.env.GATSBY_BRAND,
    };
  }

  createRequest() {
    const customer = this.createCustomerRequest();
    const order = this.createOrderRequest(customer);
    return { customer, order };
  }

  checkPromoCode(promoCode) {
    const { orderContext: { setPromoCode, unsetPromoCode } } = this.props;
    callApi(`public/checkPromoCode/${promoCode}`)
      .then((res) => {
        setPromoCode(res.promoCode);
        this.setState({ errorPromoCode: false });
      })
      .catch(() => {
        unsetPromoCode();
        this.setState({ errorPromoCode: true });
      });
  }

  removePromoCode() {
    const { orderContext: { unsetPromoCode } } = this.props;
    unsetPromoCode();
    this.setState({ errorPromoCode: false });
  }

  checkFormInputValue(name, listName) {
    const newFormInputs = { ...this.state[listName] };
    if (name === 'zipcode') {
      const message = checkZipcodeError(newFormInputs.zipcode.value);
      newFormInputs.errorZipcode.isActive = message !== '';
      newFormInputs.zipcode.error = message !== '';
      if (newFormInputs.locality.error === undefined && newFormInputs.locality.value !== '') {
        newFormInputs.locality.error = false;
      } else if (newFormInputs.locality.error !== undefined) {
        newFormInputs.locality.error = newFormInputs.locality.value === '';
      }
      this.displayDeliveryFees(newFormInputs.zipcode.value);
    } else if (name === 'email') {
      const isValid = isEmailValid(newFormInputs.email.value);
      newFormInputs.errorEmail.isActive = !isValid;
      newFormInputs.email.error = !isValid;
    } else if (newFormInputs[name].isRequired) {
      newFormInputs[name].error = newFormInputs[name].value === '';
    } else {
      newFormInputs[name].error = newFormInputs[name].value === '' ? undefined : false;
    }
    this.setState({ [listName]: { ...newFormInputs } }, () => this.checkIsDone());
  }

  checkIsDone() {
    const { formInputs } = this.state;
    return Object.values(formInputs)
      .reduce((acc, formInput) =>
        acc && (!formInput.isRequired
          || ((formInput.name === 'zipcode' || formInput.name === 'email') && !formInput.error)
          || ((formInput.name !== 'zipcode' && formInput.name !== 'email') && formInput.value !== ''))
        , true);
  }

  updateDeliveryFees(deliveryFee) {
    this.props.orderContext.updateDeliveryFee(deliveryFee);
  }

  submitOrder() {
    this.setState({ popUpWaitTimeIsOpen: true });
    // const { promoCode } = this.props.orderContext;
    callApi('registerAndOrder', 'post', this.createRequest())
      .then(() => {
        this.setState({ popUpWaitTimeIsOpen: false });
        navigate(routesMap.Step4.url);
      })
      .catch((response) => {
        this.setState({ popUpWaitTimeIsOpen: false });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  renderForm(inputList) {
    return (
      <FormContainer>
        {Object.values(inputList).map((formInput, index) => {
          if (formInput.name.includes('error')) {
            return (
              <FormInputContainer key={formInput.label}>
                <MoreInfos
                  error
                  errorOn2Lines={formInput.label.length > 20}
                  topInfo={index === 0}
                  noDisplay={!formInput.isActive}
                  small={formInput.name === 'errorEmail' && Object.values(inputList).length === 4}
                >
                  {formInput.label}
                </MoreInfos>
              </FormInputContainer>
            );
          }
          return (
            <FormInputContainer key={formInput.name}>
              <Label>{formInput.label}</Label>
              <InputAndImgContainer>
                <FormInput
                  name={formInput.name} value={formInput.value || ''}
                  type={formInput.type || 'text'}
                  size={formInput.size}
                  isMobileLarge={formInput.isMobileLarge}
                  onBlur={() => this.checkFormInputValue(formInput.name, 'formInputs')}
                  onChange={target => this.setFormInputValue(target, 'formInputs')}
                  error={formInput.error}
                />{formInput.error !== undefined && <I error={formInput.error} />}
              </InputAndImgContainer>
              {formInput.more &&
                <MoreInfos>{formInput.more}</MoreInfos>
              }
            </FormInputContainer>
          );
        })}
      </FormContainer>
    );
  }

  render() {
    const {
      deliveryFee, formInputs,
      popUpLocalityIsOpen, popUpWaitTimeIsOpen,
      errorPromoCode,
    } = this.state;
    const {
      orderContext: { clothes, slots, promoCode },
    } = this.props;
    const isDone = this.checkIsDone();
    return (
      <OrderContainer>
        <Wrapper>
          <Block>
            <OutInSideClick handleClickOutside={() => this.setPopUpIsOpen(false)}>
              <PopUpLocality isDisplayed={popUpLocalityIsOpen}>
                <PopUpTextContainer>
                  <PopUpText>
                    Oups ! Votre ville n’est pas accessible en métro.
                    Nos couturiers vont devoir se débrouiller autrement.
                  </PopUpText>
                  Pour les dédommager, les frais de déplacement sont passés à 15 €.
                </PopUpTextContainer>
                <PopUpText pointer onClick={() => this.setPopUpIsOpen(false)}>OK</PopUpText>
              </PopUpLocality>
            </OutInSideClick>
            <PopUpWaitTime isDisplayed={popUpWaitTimeIsOpen}>
              <Dots size={9} spacing={22} />
              <PopUpText marginTop={25}>Création de la commande en cours</PopUpText>
              <PopUpText>Merci de patienter quelques instants</PopUpText>
            </PopUpWaitTime>

            <ProgressBar step={3} />

            <Title2>Où retrouve-t-on votre client ?</Title2>
            <ComponentsContainer>
              <ContactInfosContainer>
                <TitleContactInfosContainer>
                  <TitleContactInfos>
                    Ses coordonnées
                  </TitleContactInfos>
                </TitleContactInfosContainer>
                {this.renderForm(formInputs)}
              </ContactInfosContainer>

              <OrderSummaryContainer
                vertical
                clothes={clothes}
                selectedDates={slots}
                canEdit
                deliveryFee={deliveryFee}
                hasZipcode={!!formInputs.zipcode.value && !formInputs.zipcode.error}
                showDiscountButtons
                promoCode={promoCode}
                checkPromoCode={this.checkPromoCode}
                removePromoCode={this.removePromoCode}
                errorPromoCode={errorPromoCode}
              />

            </ComponentsContainer>

            <ButtonContainer>
              <LogoButton onClick={() => navigate(routesMap.Step2.url)} back noZoom>Disponibilités</LogoButton>
              {isDone &&
                <StyledLink onClick={this.submitOrder}>
                  <LogoButton
                    backgroundColor={colors.navy} textColor={colors.white}
                    back logo={tick} neverHideLogo mobileFirst
                  >
                    Confirmer le RDV
                  </LogoButton>
                </StyledLink>
              }
              {!isDone &&
                <LogoButton
                  isAvailable={false}
                  backgroundColor={colors.navy} textColor={colors.white}
                  back logo={tick} neverHideLogo mobileFirst
                >
                  Confirmer le RDV
                </LogoButton>
              }
            </ButtonContainer>
          </Block>
        </Wrapper>
      </OrderContainer>
    );
  }
}

MeetingInfos.propTypes = {
  orderContext: PropTypes.shape({
    clothes: PropTypes.arrayOf(PropTypes.shape({})),
    slots: PropTypes.shape({}),
    setOrderId: PropTypes.func,
    updateDeliveryFee: PropTypes.func,
    rdv3ClicksOrder: PropTypes.shape({
      knowHow: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      fabrics: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    promoCode: PropTypes.shape({}),
  }).isRequired,
};

export default withOrderContext(withAppContext(MeetingInfos));
